<template>
  <div class="pad-container">
    <div class="flx-row ali-c flx-wr imgWrap js-btw">
      <div
        v-for="(item, index) in act_form.list"
        :key="index"
        class="mb-15"
        :style="{
          position: 'relative',
          width: item.width + 'px',
          height: item.height + 'px',
        }"
      >
        <img
          :src="picsPath(item.picture)"
          v-if="item.picture"
          class="el-image"
        />
        <el-image v-else>
          <div slot="error" class="image-slot">
            <i class="el-icon-picture-outline"></i>
          </div>
        </el-image>
      </div>
      <div class="info">首页广告推荐提示</div>
    </div>
    <el-tabs
      v-model="activePart"
      @tab-click="changeTab"
      class="mt-20"
      v-if="!storeFlag"
    >
      <el-tab-pane
        :label="shop.storeName"
        :name="String(shop.id)"
        v-for="(shop, index) in shopList"
        :key="index"
      >
        <el-form
          class="flx-row ali-c flx-wr js-btw el-form mt-24"
          label-width="80px"
          :ref="'act_form' + activePart"
          :model="act_form"
        >
          <div
            v-for="(item, index) in act_form.list"
            :key="index"
            class="zone pad-container"
          >
            <div class="ml-10 mb-20">广告区</div>
            <el-form-item label="商品主图">
              <UploadPics
                :uploadType="'main'"
                :imgLimit="1"
                :imgSize="sizeList[index].width + '*' + sizeList[index].height"
                :imgsPreview="item.picture"
                @getImgData="(x, y, z) => getImgData(x, y, z, index)"
                :key="index"
              />
            </el-form-item>
            <el-form-item label="跳转链接">
              <el-select v-model="item.turnUrl" clearable>
                <el-option
                  v-for="(item, index) in activities"
                  :key="index"
                  :label="item.activityName"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-form>
      </el-tab-pane>
    </el-tabs>
    <div v-else>
      <el-form
        class="flx-row ali-c flx-wr js-btw el-form mt-24"
        label-width="80px"
        :ref="'act_form' + activePart"
        :model="act_form"
      >
        <div
          v-for="(item, index) in act_form.list"
          :key="index"
          class="zone pad-container"
        >
          <div class="ml-10 mb-20">广告区</div>
          <el-form-item label="商品主图">
            <UploadPics
              :uploadType="'main'"
              :imgLimit="1"
              :imgSize="sizeList[index].width + '*' + sizeList[index].height"
              :imgsPreview="item.picture"
              @getImgData="(x, y, z) => getImgData(x, y, z, index)"
              :key="index"
            />
          </el-form-item>
          <el-form-item label="跳转链接">
            <el-select v-model="item.turnUrl" clearable>
              <el-option
                v-for="(item, index) in activities"
                :key="index"
                :label="item.activityName"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </div>
      </el-form>
    </div>
    <div class="flx-row ali-c js-fe mt-50">
      <el-button class="form-button" type="primary" @click="submit"
        >保 存</el-button
      >
    </div>
  </div>
</template>
<script>
import UploadPics from "@/components/UploadPics";
import { getAdConfig, saveAdConfig } from "@/api/market/ad";
import { getActivityList } from "@/api/market/activity";

export default {
  components: { UploadPics },
  data() {
    return {
      img: "",
      dataurl: "",
      dialogVisible: false,
      sizeList: [
        {
          width: 375,
          height: 110,
        },
      ],
      activePart: "1",
      shopList: [],
      act_form: {
        list: [
          {
            width: 375,
            height: 110,
            picture: "",
            turnUrl: "",
            id:""
          },
        ],
      },
      activities: [],
      storeFlag: false,
    };
  },
  mounted() {
    if (this.$store.getters.storeId) {
      this.activePart = this.$store.getters.storeId;
      this.storeFlag = true;
      this.promiseFinish();
    } else {
      this.getStoreList();
    }
  },
  methods: {
    // 获取活动列表
    getActivityList() {
      return new Promise((resolve, reject) => {
        let params = {
          storeId: this.activePart,
          pageNum: 1,
          pageSize: 9999,
          section: 1
        };
        getActivityList(params)
          .then((res) => {
            // console.log("body", res.data.body);
            let data = res.data.body;
            this.activities = data.list.filter((item) => item.status == 1);
            // console.log("activities", this.activities);
            resolve();
          })
          .catch((err) => {
            // console.log(err);
            reject();
          });
      });
    },
    promiseFinish() {
      Promise.all([this.getAdConfig(), this.getActivityList()]).then((res) => {
        // console.log(this.activities);
        // console.log(this.act_form.list);
        let ids = this.activities.map((item) => item.id);
        this.act_form.list.map((item) => {
          if (!ids.includes(item.turnUrl)) {
            item.turnUrl = "";
          }
        });
      });
    },
    //获取门店列表
    getStoreList() {
      $http({
        url: "/goods-service/store/listOfPage",
        method: "get",
      })
        .then((res) => {
          this.shopList = res.data.body.list;
          this.activePart = res.data.body.list[0].id + "";
          this.promiseFinish();
        })
        .catch((err) => {
          // console.log(err);
        });
    },
    getAdConfig() {
      return new Promise((resolve, reject) => {
        getAdConfig({ storeId: this.activePart })
          .then((res) => {
            if (res.data.body) {
              res.data.body.width = 375;
              res.data.body.height = 110;
              let data = [];
              data.push(res.data.body);
              // console.log(data)
              this.act_form.list = data;
            } else {
              this.act_form.list =[{
                width: 375,
                height: 110,
                picture: "",
                turnUrl: "",
                id:""
              }] ;
              // console.log(this.act_form.list)
            }
            resolve();
          })
          .catch((err) => {
            // console.log(err);
            reject();
          });
      });
    },
    submit() {
      let key = "act_form" + this.activePart;
      // console.log(key);
      let form = this.$refs[key];
      // console.log(form);
      this.doSubmit();
    },

    doSubmit() {
      if (!this.act_form.list[0].picture) {
        this.$message.error("请选择图片");
      }
      //  else if (!this.act_form.list[0].turnUrl) {
      //   this.$message.error("请选择跳转链接");
      // }
       else {
        this.act_form.list.forEach((item) => {
          item.storeId = this.activePart;
        });
        // console.log(this.act_form.list);
        let data = {
          storeId: this.activePart,
          picture: this.act_form.list[0].picture,
          turnUrl: this.act_form.list[0].turnUrl,
          id:this.act_form.list[0].id
          // isTurn: this.act_form.list[0].isTurn,
        };
        saveAdConfig(data).then(() => {
          this.$message.success("保存成功");
        });
      }
    },
    // 获取图片数据
    getImgData(imgData, type, params, imgIndex) {
      if (type == "main") {
        let data = this.act_form.list;
        data[imgIndex].picture = imgData.mainImg;
        this.$set(data, imgIndex, data[imgIndex]);
      }
    },
    // 切换tab
    changeTab() {
      this.promiseFinish();
    },
  },
};
</script>
<style scoped lang="scss">
.imgWrap {
  width: 690px;
  margin: 0 auto;
  color: #666;
  font-weight: normal;
  font-size: 15px;
  img {
    border-radius: 10px;
    margin-bottom: 20px;
  }
  .info {
    color: #999;
    font-size: 16px;
    margin: 0 auto;
  }
}
.el-form {
  padding: 0 10px 10px;
  box-sizing: border-box;
  width: 100%;
}
.zone {
  width: 48%;
  margin-top: 20px;
}
.capture {
  width: 300px;
}
.el-image {
  background: rgba(238, 238, 238, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
}
.size {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 10px 0 0 10px;
  background: rgba(255, 255, 255, 0.2);
}
</style>
